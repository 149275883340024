import { breakpoints } from '@theme/breakpoints';
import styled, { css } from 'styled-components';

interface ProgressLabelProps {
  $filled?: boolean;
  $active?: boolean;
  $withLink?: boolean;
}

function nameFilledOrActiveStyle({ $active, $withLink, $filled }: ProgressLabelProps) {
  return css`
    color: ${$active || $filled ? 'var(--color-white)' : '#090909'};
    font-weight: ${$active ? 'bold' : '200'};
    margin-top: ${$active ? '2px' : '0'};
    cursor: ${$withLink ? 'pointer' : 'default'};
  `;
}

function numFilledOrActiveStyle({ $active }: ProgressLabelProps) {
  return css`
    background: ${$active
      ? 'transparent linear-gradient(180deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat'
      : 'var(--color-white)'};
    color: ${$active ? 'var(--color-white)' : '#090909'};
  `;
}

export default styled.span`
  display: flex;
  align-items: center;

  &:hover {
    .stepName {
      text-decoration: ${({ $withLink }) => ($withLink ? 'underline' : 'none')};
    }
  }

  .stepName {
    display: none;

    @media ${breakpoints.mobilelg} {
      display: block;
      font-size: 0.875rem;
      font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana,
        'sans-serif';
      overflow-wrap: break-word;
      word-break: break-word;
      text-align: center;
      line-height: 30px;
      color: #090909;
      white-space: nowrap;
      ${nameFilledOrActiveStyle};
    }
  }

  .stepNum {
    display: flex;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: solid 1px #b5b5b5;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'sans-serif';
    font-weight: bold;
    color: #090909;
    ${numFilledOrActiveStyle};

    @media ${breakpoints.mobilelg} {
      display: none;
    }
  }

  span {
    margin-left: 0.2rem !important;
  }
`;
