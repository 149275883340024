import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import ErrorIcon from '@public/icons/Featured-1.svg';

export const StyledErrorBar = styled.div`
  background: var(--colors-secondary-15);
  border: 1px solid var(--colors-error);
  margin-bottom: 0;
  margin-top: 3rem;
  padding: 0.75rem 60px 0.75rem 1rem;
  max-width: 30rem;
  border-radius: 0.5rem;
  color: var(--colors-secondary);
  font-weight: normal;
  position: relative;

  &:after {
    content: '!';
    background-color: var(--color-danger);
    color: var(--color-white);
    width: 50px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
    font-weight: 900;
    border-top-right-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
`;
