import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export default styled.div<{ isSticky: boolean; isSummaryOpen: boolean; isAlwaysOnScreen: boolean }>`
  ${({ isSticky }) =>
    isSticky
      ? `
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .content {
      overflow-y: scroll;
      height: 100%;

      & > a[class*="StyledLogo"] {
        margin: 1rem 1.5rem 4rem 1.5rem;
      }
    }

    .mini-basket {
      box-shadow: none;
    }

    @media ${breakpoints.tablet} {
      .show-summary {
        display: none;
      }
    }
  `
      : ''}

  ${({ isSummaryOpen }) =>
    isSummaryOpen
      ? `
      position: fixed;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 100;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .content {
        overflow-y: scroll;
        height: 100%;

        & > a[class*="StyledLogo"] {
          margin: 1rem 1.5rem 4rem 1.5rem;
        }
      }

      .mini-basket {
        box-shadow: none;
      }

      @media ${breakpoints.tablet} {
        .show-summary {
          display: none;
        }
      }
    background: var(--color-blue) 0% 0% no-repeat padding-box;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    .basket-contents {
      max-height: unset;
      overflow-y: unset;
    }
  `
      : ''}

  .checkout-footer {
    &__nav {
      padding: 1rem 2rem;
      display: flex;
      gap: 1rem;
      justify-content: center;
      background-color: blue;
    }
  }

  &.mobile-only {
    @media ${breakpoints.tablet} {
      display: none;
    }
  }

  .show-summary + div {
    @media ${breakpoints.mobilelgMax} {
      width: auto;
    }
  }
`;
