import styled from 'styled-components';

export default styled.div`
  display: flex;
  justify-content: center;

  button {
    color: var(--colors-primary-bright);
    font-size: 1.2rem;
    font-weight: lighter;
    text-decoration: underline;
    margin-top: 1rem;
  }
`;
