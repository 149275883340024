/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useState } from 'react';
import usePersistState from '@hooks/usePersistState';

export interface OtsContextI {
  ots_rcpid: string | null;
  setOtsRcpid: (ots_rcpid?: string | null) => void;
}

export const OtsContext = createContext<OtsContextI>({
  ots_rcpid: '',
  setOtsRcpid: () => {},
});

export const OtsProvider = ({ children }) => {
  const [ots_rcpid, setOtsRcpidState] = usePersistState<string | null>('', 'ots_rcpid', 3600000);

  const setOtsRcpid = useCallback((ots_rcpid) => setOtsRcpidState(ots_rcpid), [setOtsRcpidState]);
  const data = {
    ots_rcpid,
    setOtsRcpid,
  };
  return <OtsContext.Provider value={data}>{children}</OtsContext.Provider>;
};
