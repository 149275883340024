export interface Props {
  className?: string;
  data: TrustBoxData;
}
``;

export interface TrustBoxData {
  heading: string;
  type: WidgetType;
  display: boolean;
  headingStyles?: string;
  backgroundColor?: string;
  disableClick?: boolean;
}

export type StyledWidgetProps = {
  type: WidgetType;
  backgroundColor?: string;
};

export enum WidgetType {
  CAROUSEL = 'reviews',
  MICROSTAR = 'ratings',
  MINI = 'mini',
}
