import Link from 'next/link';
import React, { FC } from 'react';

import StyledProgressBar from './styled/StyledProgressBar';
import StyledProgressBarStep from './styled/StyledProgressBarStep';
import StyledProgressLabel from './styled/StyledProgressLabel';
import { ProgressBarStepT, ProgressBarT } from './types';

// @todo This needs to have an "inverted" mode, or dark/light, for different headings.

const ProgressBarStep: FC<any> = ({
  step,
  index,
  filled = false,
}: {
  step: ProgressBarStepT;
  index: number;
  filled: boolean;
}) => {
  const renderContent = () => {
    const withLink = Boolean(step.href && filled);

    const content = (
      <StyledProgressLabel
        $filled={filled}
        $active={step.active}
        $withLink={withLink}
        className={filled ? 'completed' : ''}
      >
        <span className="stepNum">{index + 1}</span>
        <span className="stepName">{step.name}</span>
      </StyledProgressLabel>
    );

    if (step.href && withLink) {
      return (
        <Link href={step.href} passHref>
          {content}
        </Link>
      );
    }

    return content;
  };

  return (
    <StyledProgressBarStep $filled={filled} $active={step.active}>
      {renderContent()}
    </StyledProgressBarStep>
  );
};

export const ProgressBar: FC<any> = ({ steps }: ProgressBarT) => {
  const stepValue = 100 / steps.length;
  const activeStepIndex = steps.findIndex((step) => step.active);
  const currentStepValue = stepValue * (activeStepIndex + 1);
  return (
    <StyledProgressBar $steps={steps.length} $progressvalue={currentStepValue}>
      {steps?.map((step, index) => (
        <ProgressBarStep
          key={step.name}
          step={step}
          index={index}
          filled={index < activeStepIndex}
        />
      ))}
    </StyledProgressBar>
  );
};
