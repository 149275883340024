import styled from 'styled-components';

export default styled.div<{ fullWidth?: boolean }>`
  padding: 10px 15px;
  background-color: ${({ fullWidth }) =>
    fullWidth ? 'rgba(217, 217, 217, 0.3)' : 'var(--color-white)'};
  border-radius: 5px;
  font-size: 0.875rem;
  & > strong {
    margin-bottom: 5px;
    display: block;
  }
  & > ul {
    display: block;
    padding: 0;
    margin: 0;
    list-style: none;

    & > li {
      display: flex;
      width: 100%;

      & > .divider {
        margin: 0 2px 6px 5px;
        display: block;
        flex: 1;
        border-bottom: 2px dotted var(--colors-grey-100);
      }
    }
  }
`;
