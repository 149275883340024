import styled from 'styled-components';

export default styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem 2rem;
  margin: 2rem 0;

  background: #dce3fb;
  border-radius: 10px;
  color: #090909;
  p {
    color: #090909;
  }
`;
