import React, { FC } from 'react';
import { fromMarkdown } from '@lib/utils';
import { SectionHeadingT } from './types';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const StyledSectionHeading = styled.div`
  text-align: center;
  padding-bottom: 2rem;
  padding-top: 1.5rem;

  .title {
    font-size: 2.2rem;
    font-weight: 500;
    line-height: 40px;

    @media ${breakpoints.tablet} {
      font-size: 3.375rem;
      line-height: 60px;
    }
  }
`;

export const SectionHeading: FC<SectionHeadingT> = ({ heading, subHeading }) => (
  <StyledSectionHeading>
    <h2 className="title">{fromMarkdown({ text: heading })}</h2>
    {subHeading && <h3 className="subtitle">{fromMarkdown({ text: subHeading })}</h3>}
  </StyledSectionHeading>
);
