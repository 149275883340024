import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  @media ${breakpoints.mobilelgMax} {
    display: flex;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    width: 100%;

    &:has(a:only-child) {
      width: 45%;
      justify-content: center;
    }
  }

  display: flex;
  gap: 3rem;
  justify-content: center;
  align-items: center;
`;
