import styled from 'styled-components';

export default styled.div`
  margin-top: 1rem;
  a {
    text-decoration: underline;
  }

  &.booleanInput {
    position: relative;

    &--1 {
      &:before {
        content: '1.';
      }
    }

    &--2 {
      &:before {
        content: '2.';
      }
    }

    &--3 {
      &:before {
        content: '3.';
      }
    }

    &--4 {
      &:before {
        content: '4.';
      }
    }

    &--1,
    &--2,
    &--3,
    &--4 {
      &:before {
        position: absolute;
        left: -1rem;
        font-weight: 300;
        font-size: 0.9rem;
      }
    }
  }

  &.smallBooleanInput .radio-wrapper {
    @media screen and (max-width: 640px) {
      width: 100%;
      justify-content: center;
    }
  }
`;
