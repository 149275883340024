import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

const { desktop } = breakpoints;

export default styled.div<any>`
  display: flex;
  margin-top: 0.6rem;
  padding: 0 2rem;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > p {
    margin-top: 3rem;
    color: var(--colors-black);
    font-size: 1.2rem;
  }

  @media ${desktop} {
    flex-direction: column;
    flex-wrap: wrap;
    margin-top: 0;

    > *:first-child {
      margin-top: 0;
    }
  }
`;
