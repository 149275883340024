import styled, { css } from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

type Props = {
  $show: boolean;
  $fullWidth?: boolean;
};

function isFullWidth({ $fullWidth = false }) {
  if ($fullWidth) {
    return css`
      background: none;
      box-shadow: none;
      max-width: 44rem !important;
      position: static !important;
    `;
  }
}

function isShow({ $show = false }) {
  if ($show) {
    return css`
      display: block;
    `;
  }
}

function isH3FullWith({ $fullWidth = false }) {
  if ($fullWidth) {
    return css`
      background: var(--color-white);
      color: var(--colors-primary-bright);
      font-size: 2rem;
      font-weight: 500;
    `;
  }
}

function isLastDivFullWith({ $fullWidth = false }) {
  if ($fullWidth) {
    return css`
      padding: 0;

      @media ${breakpoints.desktop} {
        padding: 0 2rem;
      }
    `;
  }
}

export default styled.div<Props>`
  position: relative;
  max-width: 100%;
  background: #f4f4f4 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 26px #676d9a29;
  opacity: 1;
  align-self: flex-start;
  border-radius: 5px;
  margin-top: 3rem;

  @media ${breakpoints.tablet} {
    position: sticky;
    top: 1rem;
    display: block;
  }

  @media ${breakpoints.desktopLarge} {
    width: 100%;
    max-width: 26rem;
  }

  & > h3 {
    display: block;
    background: linear-gradient(to bottom, #f4111b 0%, var(--color-primary) 100%);
    padding: 1.25rem 2rem;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    ${isH3FullWith}
  }

  & > div {
    padding: 2rem;
    ${isLastDivFullWith}
  }

  ${isFullWidth}
  ${isShow}
`;
