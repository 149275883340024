import useBasketContext from '@hooks/useBasketContext';
import usePersistState from '@hooks/usePersistState';
import { getRunsOutAt, isRunning } from '@lib/utils/checkoutCountdown';
import {
  CheckoutCountdownDataI,
  CheckoutCountdownProviderT,
} from '@providers/CheckoutCountdownProvider/types';
import { useRouter } from 'next/router';
import { createContext, FC, useCallback, useEffect } from 'react';

export const CheckoutCountdownContext = createContext<CheckoutCountdownDataI>({
  runsOutAt: null,
  isOvertime: false,
  start: () => {},
  stop: () => {},
});

// 1hr
const STATE_EXPIRY_TIME = 3600000;

const convertDateToStateValue = (storageValue: any) =>
  storageValue ? new Date(storageValue) : null;

const convertDateToStorageValue = (stateValue: any) =>
  stateValue ? stateValue.toISOString() : null;

export const CheckoutCountdownProvider: FC<CheckoutCountdownProviderT> = ({ children }) => {
  const [isOvertime, setIsOvertime] = usePersistState<boolean>(
    false,
    'checkoutIsOvertime',
    STATE_EXPIRY_TIME,
  );

  const [runsOutAt, setRunsOutAt] = usePersistState<Date | null>(
    null,
    'checkoutRunsOutAt',
    STATE_EXPIRY_TIME,
    {
      convertToStateValue: convertDateToStateValue,
      convertToStorageValue: convertDateToStorageValue,
    },
  );

  const { clearBasket } = useBasketContext();

  const router = useRouter();

  const start = useCallback(() => {
    if (!isRunning(runsOutAt)) {
      setIsOvertime(false);
      setRunsOutAt(getRunsOutAt({ isOvertime: false }));
    }
  }, [runsOutAt, isOvertime, setRunsOutAt, setIsOvertime]);

  const stop = useCallback(() => {
    setIsOvertime(false);
    setRunsOutAt(null);
  }, [setRunsOutAt, setIsOvertime]);

  useEffect(() => {
    if (!runsOutAt) return;

    let remainingTime = runsOutAt.getTime() - new Date().getTime();
    if (remainingTime < 0) {
      remainingTime = 0;
    }

    const timeout = setTimeout(() => {
      if (isOvertime) {
        setRunsOutAt(null);
        setIsOvertime(false);
        // When the overtime runs out clear the basket
        // and redirect the user to HomePage
        clearBasket();
        router.push('/');
      } else {
        // When the regular time runs out, start overtime
        setIsOvertime(true);
        setRunsOutAt(getRunsOutAt({ isOvertime: true }));
      }
    }, remainingTime);

    return () => clearTimeout(timeout);
  }, [clearBasket, isOvertime, router, runsOutAt, setIsOvertime, setRunsOutAt]);

  return (
    <CheckoutCountdownContext.Provider value={{ runsOutAt, isOvertime, start, stop }}>
      {children}
    </CheckoutCountdownContext.Provider>
  );
};
