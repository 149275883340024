import React, { useEffect, useRef, useState } from 'react';
import { StyledInput } from './styled';

import { InputProps } from './types';

export const Input: React.FC<
  InputProps &
    React.InputHTMLAttributes<HTMLInputElement> &
    React.TextareaHTMLAttributes<HTMLTextAreaElement>
> = ({
  label,
  renderLabel,
  placeholder,
  error,
  value,
  fullWidth,
  textArea,
  className,
  onChange,
  onFocus,
  onBlur,
  onKeyEnter,
  onKeyEscape,
  onKeyArrowUp,
  onKeyArrowDown,
  type,
  fieldName,
  name,
  tooltip,
  required,
  options,
  formData,
  autoComplete,
  booleanYesLabel,
  booleanNoLabel,
  ...props
}) => {
  const [isActive, setIsActive] = useState(false);
  const [isFilled, setIsFilled] = useState(Boolean(value));
  const [isIOSSafari, setIsSafari] = useState<boolean>(false);
  const [isAndroid, setIsAndroid] = useState<boolean>(false);
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    const iOS = !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
    const webkit = !!ua.match(/WebKit/i);
    const iOSSafari = iOS && webkit && !ua.match(/CriOS/i);
    setIsSafari(iOSSafari);
  }, []);

  useEffect(() => {
    const ua = window.navigator.userAgent;
    const android = !!ua.match(/Android/i);
    const webkit = !!ua.match(/WebKit/i);
    const androidWebkit = android && webkit;
    setIsAndroid(androidWebkit);
  }, []);

  return (
    <StyledInput
      isActive={isActive}
      isFilled={isFilled}
      fullWidth={fullWidth}
      textArea={textArea}
      error={Boolean(error)}
      className={className}
      isIOSSafari={isIOSSafari}
      isAndroid={isAndroid}
    >
      {type === 'date' && (
        <div className="date__wrapper relative">
          <input
            type="date"
            name={name}
            value={value}
            autoComplete={autoComplete}
            placeholder="YYYY-MM-DD"
            min="1900-01-01"
            onFocus={(event) => {
              setIsActive(true);

              console.log('onFocus', event);

              if (onFocus) {
                onFocus(event);
              }
            }}
            onBlur={(event) => {
              setIsActive(false);

              console.log('onBlur', event);
              if (onBlur) {
                onBlur(event);
              }
            }}
            onChange={(event) => {
              console.log('onChange', event);

              setIsFilled(Boolean(event?.target?.value));
              if (onChange) {
                onChange(event);
              }
            }}
          />
          {Boolean(error) ? <span className="input-error-mark">!</span> : null}
        </div>
      )}
      {!textArea && ['boolean', 'checkbox-group', 'date'].indexOf(type || '') === -1 && (
        <div className="relative">
          <input
            {...props}
            ref={ref}
            value={value}
            type={type === 'date' && !isIOSSafari ? 'text' : type}
            name={name}
            placeholder={isActive ? '' : `${placeholder}${required ? '*' : ''}`}
            onFocus={(event) => {
              setIsActive(true);

              if (onFocus) {
                onFocus(event);
              }
            }}
            onBlur={(event) => {
              setIsActive(false);
              if (onBlur) {
                onBlur(event);
              }
            }}
            // I'm aware this is getting unmanageable but it's quite flexible.
            onKeyUp={(event) => {
              if (event.key === 'Escape') {
                ref.current?.blur();
                if (onKeyEscape) {
                  onKeyEscape(event);
                }
              }
              if (event.key === 'Enter') {
                if (onKeyEnter) {
                  onKeyEnter(event);
                }
              }
              if (event.key === 'ArrowUp') {
                if (onKeyArrowUp) {
                  onKeyArrowUp(event);
                }
              }
              if (event.key === 'ArrowDown') {
                if (onKeyArrowDown) {
                  onKeyArrowDown(event);
                }
              }
            }}
            onChange={(event) => {
              setIsFilled(Boolean(event?.target?.value));
              if (onChange) {
                onChange(event);
              }
            }}
            autoComplete={autoComplete}
          />
          {Boolean(error) ? <span className="input-error-mark">!</span> : null}
        </div>
      )}
      {textArea && (
        <textarea
          {...props}
          value={value}
          onFocus={() => {
            setIsActive(true);
          }}
          onBlur={() => {
            setIsActive(false);
          }}
          onChange={(event) => {
            setIsFilled(Boolean(event?.target?.value));
            if (onChange) {
              onChange(event);
            }
          }}
        />
      )}
      {type === 'boolean' && (
        <>
          <legend>
            {label}
            {renderLabel ? renderLabel() : null}
            {required && typeof label === 'string' && '*'}
          </legend>
          <div className="radio-wrapper">
            <label
              htmlFor={`${name}-yes`}
              className={`radio-row ${!!value ? 'radio-row--selected' : ''} relative`}
            >
              <input
                {...props}
                value={1}
                type="radio"
                name={name}
                id={`${name}-yes`}
                onChange={(event) =>
                  onChange && onChange({ ...event, target: { ...event.target, value: '' } })
                }
                checked={!!value}
              />
              <span className="radio-row__label">{booleanYesLabel || 'Yes'}</span>
            </label>
            <label
              htmlFor={`${name}-no`}
              className={`radio-row ${!value ? 'radio-row--selected' : ''} relative`}
            >
              <input
                {...props}
                value={0}
                type="radio"
                name={name}
                id={`${name}-no`}
                onChange={(event) =>
                  onChange && onChange({ ...event, target: { ...event.target, value: '' } })
                }
                checked={typeof value !== 'undefined' && !value}
              />
              <span className="radio-row__label">{booleanNoLabel || 'No'}</span>
            </label>
          </div>
        </>
      )}
      {type === 'checkbox-group' && options && (
        <div className="checkbox-group">
          <legend className="checkbox-group__legend">
            {label}
            {required && '*'}
          </legend>
          <div className="checkbox-group__wrapper">
            {options.map((option) => (
              <label
                key={option.value}
                htmlFor={`radio--${option.alias}`}
                className={`radio-row ${!!option.value ? 'radio-row--selected' : ''} relative`}
              >
                <input
                  {...props}
                  value={option.value}
                  type="checkbox"
                  name={name}
                  id={`radio--${option.alias}`}
                  onChange={(event) => {
                    onChange && onChange(event);
                  }}
                  checked={formData[name || ''].includes(option.value)}
                />
                <span className="radio-row__label">{option.label}</span>
              </label>
            ))}
          </div>
        </div>
      )}
      {error ? <span className="error">{error}</span> : null}
      {label && ['boolean', 'checkbox-group'].indexOf(type || '') === -1 && (
        <label
          className={value || type === 'date' ? `hasValue ${type}__label` : `${type}__label`}
          htmlFor={name}
        >
          {label}
          {required && '*'}
        </label>
      )}
    </StyledInput>
  );
};
