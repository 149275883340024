import { useState, useEffect, useCallback, Dispatch, SetStateAction } from 'react';
import browserStorage from 'store';

const composeStorageContent = (
  content: any,
  expireTime?: number,
  convertToStorageValue?: (stateValue: any) => any,
) => {
  const finalContent = convertToStorageValue ? convertToStorageValue(content) : content;

  if (!expireTime) return finalContent;

  return {
    content: finalContent,
    expireAt: Date.now() + expireTime,
  };
};

const composeState = (value: any, convertToStateValue?: (storageValue: any) => any) => {
  if (convertToStateValue) {
    return convertToStateValue(value);
  }

  return value;
};

const getValueFromStorage = (storageKey: string, expireTime?: number) => {
  const storageInBrowser = browserStorage.get(storageKey);

  if (storageInBrowser) {
    if (storageInBrowser.expireAt) {
      if (Date.now() < storageInBrowser.expireAt) {
        return storageInBrowser.content;
      }
    } else if (!expireTime) {
      return storageInBrowser;
    }
  }

  return undefined;
};

export default function usePersistState<S>(
  initialState: S | (() => S),
  storageKey: string,
  expireTime?: number,
  options?: {
    convertToStorageValue?: (stateValue: S) => any;
    convertToStateValue?: (storageValue: any) => S;
  },
): [S, Dispatch<SetStateAction<S>>] {
  const [state, _setInternalState] = useState(initialState);

  const setInternalState = useCallback(
    (value: any) => {
      _setInternalState(composeState(value, options?.convertToStateValue));
    },
    [options?.convertToStateValue],
  );

  useEffect(() => {
    const valueFromStorage = getValueFromStorage(storageKey, expireTime);
    if (valueFromStorage !== undefined) {
      setInternalState(valueFromStorage);
    }
  }, [expireTime, setInternalState, storageKey]);

  const setState = useCallback(
    (newState) => {
      let value: any;
      if (typeof newState === 'function') {
        value = newState(state);
      } else {
        value = newState;
      }

      browserStorage.set(
        storageKey,
        composeStorageContent(value, expireTime, options?.convertToStorageValue),
      );
      setInternalState(value);
    },
    [storageKey, expireTime, options?.convertToStorageValue, setInternalState, state],
  );

  return [state, setState];
}
