import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

const { desktopLarge, tablet, desktopMax, mobilelgMax } = breakpoints;

function isSelectedStyle({ $isSelected = false }) {
  if ($isSelected) {
    return css`
      border-radius: 0 !important;
    `;
  }
}

export default styled.div`
  background-image: linear-gradient(#ef1120 0%, #0402fd 60%);
  background-color: #0402fd;
  padding: ${rem(25)} ${rem(20)} ${rem(10)} ${rem(20)};
  color: var(--colors-white);
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;

  @media ${tablet + ` and ` + desktopMax} {
    height: 430px;
  }

  @media ${desktopLarge} {
    height: 320px;
  }

  ${isSelectedStyle}
`;
