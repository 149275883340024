import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  background-color: #f7f9ff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  border: 1px solid rgba(236, 237, 246, 1);
  font-family: Roboto;

  .countdownTimer {
    font-family: Roboto;
    font-size: 18px;
    font-weight: 700;
    line-height: 18px;
    color: rgba(86, 86, 97, 1);
    margin-right: 4px;

    @media ${breakpoints.tablet} {
      font-size: 24px;
      font-weight: 700;
    }
  }

  .countdownLabel {
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: rgba(86, 86, 97, 1);

    @media ${breakpoints.tablet} {
      font-size: 18px;
    }
  }
`;
