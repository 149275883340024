import React, { FC } from 'react';
import styled from 'styled-components';
import { fromMarkdown } from '@lib/utils';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';

import { Icon } from '@components/Atoms';
import star from '@public/icons/Star-Vector.svg';
import quoteMarks from '@public/icons/Quote-Marks.svg';
import bennyPointingUp from '@public/benny-pointing-right-up-lg.png';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CarouselNextArrow, CarouselPrevArrow } from '@components/Atoms/CarouselArrow';
import Image from 'next/image';

interface CustomerReviewsT {
  customerReview: Array<ReviewI>;
  display: boolean;
  title: string;
}

interface ReviewI {
  stars: number;
  quote: string;
  keyquote: string;
  reviewer: string;
}

const StyledCustomerReviews = styled.section`
  background: var(--color-lightest-blue);
  position: relative;
  padding-bottom: ${rem(150)};

  &.bg-white {
    background: var(--color-white);
  }

  @media ${breakpoints.smallDesktop} {
    & > div {
      display: flex;
      flex-direction: row;
      position: relative;
    }
  }

  .reviews {
    &__content {
      display: flex;
      flex-direction: column;
      width: 100%;

      @media ${breakpoints.smallDesktop} {
        width: 60%;
        margin-left: auto;
      }

      @media ${breakpoints.desktopLarge} {
        width: 65%;
      }
    }

    &__title {
      text-align: left;
      margin-right: auto;
      margin-bottom: 2rem;
      font-size: ${rem(30)};
      color: var(--color-primary);
      font-weight: bold;

      @media ${breakpoints.smallDesktop} {
        font-size: ${rem(45)};
      }
    }

    &__carousel {
      background: var(--color-white);
      box-shadow: rgba(116, 116, 196, 0.5) 0px 14px 24px;
      border-radius: 10px;
      padding: ${rem(30)} ${rem(70)} ${rem(30)} ${rem(30)};
      width: 100%;

      .slick-slider {
        .slick-next {
          width: 51px;
          height: 51px;
          border-radius: 0;
          right: unset;
          left: calc(100% + 19px);

          &:before {
            transform: scale(1.5);
            display: inline-block;
            transition: transform 0.3s;
          }

          &:hover {
            &:before {
              transform: translateX(5px) scale(1.5);
            }
          }

          @media ${breakpoints.desktopLarge} {
            border-radius: 50%;
            left: 95%;
          }
        }

        .slick-dots {
          text-align: start;
          bottom: 0;

          @media ${breakpoints.desktopLarge} {
            padding-left: 2.5rem;
          }

          li {
            button {
              &:before {
                width: 12px;
                height: 12px;
                border-radius: 50%;
                background: var(--color-blue);
                opacity: 1;
                color: transparent;
              }
            }

            &.slick-active {
              button {
                &:before {
                  background: var(--color-primary);
                }
              }
            }
          }
        }

        .slick-track {
          @media ${breakpoints.smallDesktop} {
            display: flex;
            gap: 1rem;
          }
        }
      }
    }

    &__benny {
      position: absolute;
      bottom: -33px;
      left: -15%;
      height: ${rem(200)};
      width: 100%;

      img {
        object-fit: contain;
      }

      @media ${breakpoints.smallDesktop} {
        left: 3rem;
        width: ${rem(288)};
        height: ${rem(380)};
        bottom: -220px;
      }

      @media ${breakpoints.desktopLarge} {
        left: 5rem;
        width: ${rem(388)};
        height: ${rem(480)};
        bottom: -230px;
      }
    }
  }
`;

const StyledReview = styled.div`
  @media ${breakpoints.desktopLarge} {
    max-width: 80%;
  }

  .review {
    &__header {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      @media ${breakpoints.smallDesktop} {
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
      }
    }

    &__quote-marks {
      width: ${rem(31)};
      height: ${rem(31)};
      @media ${breakpoints.smallDesktop} {
        margin-bottom: 1rem;
      }
    }

    &__stars {
      display: flex;
      gap: 0.5rem;

      @media ${breakpoints.smallDesktop} {
        order: 3;
        position: relative;
        top: -0.25rem;
      }

      @media ${breakpoints.desktopLarge} {
        margin-left: 1rem;
      }
    }

    &__key-quote {
      font-size: ${rem(21)};
      font-weight: bold;
      color: var(--color-black);
      font-family: var(--font-geomanist);

      @media ${breakpoints.smallDesktop} {
        font-size: ${rem(30)};
        margin-bottom: 0;
      }
    }

    &__content {
      @media ${breakpoints.desktopLarge} {
        padding-left: 2.5rem;
      }
    }

    &__quote {
      & > * {
        font-size: ${rem(18)};
      }

      @media ${breakpoints.smallDesktop} {
        & > * {
          font-size: ${rem(22)};
        }
      }
    }

    &__reviewer {
      font-size: ${rem(16)};
      font-style: italic;
      margin-bottom: 2rem;
    }
  }
`;

const Review: FC<ReviewI> = ({ stars, keyquote, quote, reviewer }) => {
  const starRating: React.ReactElement[] = [];

  for (let i = 0; i < stars; i++) {
    starRating.push(<Icon icon={star.src} altText="review star" xxs={true} key={i} />);
  }

  return (
    <StyledReview className="review">
      <div className="review__header">
        <Icon
          icon={quoteMarks.src}
          altText="quote marks"
          sm={true}
          className="review__quote-marks"
        />
        <div className="review__stars">{starRating}</div>
        <p className="review__key-quote">{keyquote}</p>
      </div>
      <div className="review__content">
        <div className="review__quote">{fromMarkdown({ text: quote, isHeading: false })}</div>
        <p className="review__reviewer">- {reviewer}</p>
      </div>
    </StyledReview>
  );
};

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <CarouselNextArrow />,
};

export const CustomerReviews: FC<CustomerReviewsT> = ({ display, title, customerReview }) => {
  if (!display) return null;

  const Reviews = customerReview?.map((review, key) => {
    const Component = <Review key={key} {...review} />;

    return Component;
  });

  return (
    <StyledCustomerReviews className="slice reviews">
      {!!customerReview?.length && (
        <>
          <div className="flex-col slice__inner">
            <div className="reviews__benny">
              <Image src={bennyPointingUp.src} layout="fill" alt="Benny pointing up" />
            </div>
            <div className="reviews__content">
              <h2 className="reviews__title">{title}</h2>
              <div className="reviews__carousel">
                <Slider {...settings}>{Reviews}</Slider>
              </div>
            </div>
          </div>
        </>
      )}
    </StyledCustomerReviews>
  );
};
