import styled, { css } from 'styled-components';
import { rem } from 'polished';

function isAddonStyle({ $isAddon = false }) {
  if ($isAddon) {
    return css`
      margin-bottom: 0;
      border-bottom: 0;
    `;
  }
}

export default styled.div<any>`
  flex: 1;
  max-height: 100px;
  p {
    font-family: var(--fonts-family-primary);
    margin: 0;
    line-height: 20px;
    color: var(--colors-white);
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: 0.02em;
    margin-right: 1rem;
    text-align: center;
  }
  ${isAddonStyle}
`;
