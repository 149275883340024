import styled from 'styled-components';
import { rem } from 'polished';
import { breakpoints } from '@theme/breakpoints';

const StyledDescription = styled.div<{
  color?: string;
  subtitleColor?: string;
  addHeadingGradient: boolean;
  isHome?: boolean;
}>`
  margin: 0.5rem 0 0.5rem;
  p {
    font-family: var(--font-geomanist);
    color: ${(props) => props.color || `var(--color-black)`};
    font-size: 1.33rem;
    font-weight: 400;

    &:last-child {
      margin-top: -0.5rem;
      font-weight: 300;
    }
  }

  .headingGradient {
    font-family: var(--fonts-family-secondary);
    color: var(--colors-primary);
    font-size: ${rem(45)};
    line-height: 1;

    ${({ addHeadingGradient }) =>
      addHeadingGradient &&
      `
        background: -webkit-linear-gradient(180deg, #f4111b 30%, #0402fd);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: 700;
    `}
    @media ${breakpoints.mobilelg} {
      font-size: ${rem(72)};
    }
    @media ${breakpoints.desktop} {
      max-width: clamp(50%, 100%, 50rem);
      font-size: ${rem(90)};
    }
    @media ${breakpoints.desktopLarge} {
      max-width: 100%;
    }
  }

  .subtitle {
    color: ${(props) => props.subtitleColor || `var(--color-primary)`};
    line-height: 1;
    margin-top: 0.5rem;
    font-size: ${rem(24)};

    @media (${breakpoints.tablet}) {
      font-size: ${rem(30)};
    }

    @media (${breakpoints.desktopLarge}) {
      font-size: ${rem(40)};
      margin-top: 0;
      max-width: 100%;
      margin-bottom: 0;
    }
  }
`;

export { StyledDescription };
