import styled from 'styled-components';
import { rem } from 'polished';

export default styled.div`
  padding: ${rem(15)} ${rem(20)} ${rem(10)} ${rem(20)};
  margin: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
`;
