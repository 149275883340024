import styled from 'styled-components';

export default styled.div`
  text-align: center;
  padding: 0 1rem;
  margin: 0 0 1rem;
  line-height: 1.3;
  color: var(--colors-primary-bright);
  font-size: 1.8rem;
  font-family: var(--fonts-family-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
