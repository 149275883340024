import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

export default styled.div<{ $steps: number; $progressvalue: number }>`
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(${(props) => props.$steps}, minmax(0, 1fr));
  max-width: 100%;
  position: relative;
  padding: 0 0.25rem;

  @media ${breakpoints.desktop} {
    gap: 1rem;
  }

  @media ${breakpoints.mobileMax} {
    :before {
      content: '';
      position: absolute;
      top: 15px;
      left: 30px;
      right: 30px;
      height: 1px;
      background-color: #b5b5b5;
    }
  }

  @media ${breakpoints.mobilelg} {
    :before {
      content: '';
      height: 29px;
      background: rgba(255, 255, 255, 1);
      box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.16) inset;
      opacity: 1;
      position: absolute;
      border-radius: 70px;
      width: 100%;
      z-index: -1;
      top: 0;
    }

    :after {
      content: '';
      height: 29px;
      background: transparent linear-gradient(90deg, #f4111b 0%, #0402fd 100%) 0% 0% no-repeat
      padding-box;
      box-shadow: inset 0px 3px 6px #00000029;
      border-radius: 70px;
      opacity: 1;
      position: absolute;
      border-radius: 70px;
      width: ${(props) => props.$progressvalue}%;
      z-index: -1;
      top: 0;
    }
  }
`;
