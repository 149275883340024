/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useCallback, useState, FC } from 'react';
import usePersistState from '@hooks/usePersistState';

import usePromotionsContext from '@hooks/usePromotionsContext';
import usePostcodeContext from '@hooks/usePostcodeContext';

import { BasketItemT } from '@lib/types';
import { BasketDataI, BasketProviderT } from './types';
import { getMonthlyPriceCalculated, getOriginalPrice, getProductPromotion } from '@lib/utils';
import * as gtag from '@lib/utils/gtag';
import useGAEvent from '@hooks/useGAEvent';

export const BasketContext = createContext<BasketDataI>({
  basketItem: null,
  setBasketItem: (
    id: string,
    originalPrice?: string | number,
    monthlyPriceCalculated?: string | number,
  ) => id,
  monthlyPriceCalculated: null,
  addons: [],
  setAddons: (_: BasketItemT[]) => {},
  bundle: false,
  setBundle: () => {},
  setBundleProducts: () => {},
  getBasketItemProduct: () => null,
  getAddonsProducts: () => [],
  isHomePhoneAddonSelected: () => false,
  promoCodes: [],
  setPromoCode: () => [],
  clearBasket: () => {},
  removePromoCode: () => [],
  removeAddon: (addonId: BasketItemT) => {},
  isSelectedItem: (id: string, isAddon: boolean, hasHomePhone: boolean, homePhoneProduct: any) =>
    !id,
  isBasketPopupOpen: false,
  setIsBasketPopupOpen: (isBasketPopupOpen: boolean) => isBasketPopupOpen,
});

// 1hr
const STATE_EXPIRY_TIME = 3600000;

export const BasketProvider: FC<BasketProviderT> = ({ children, products }) => {
  const [isBasketPopupOpen, setIsBasketPopupOpen] = useState<boolean>(false);
  const [basketItem, _setBasketItem] = usePersistState<BasketItemT | null>(
    null,
    'basketItem',
    STATE_EXPIRY_TIME,
  );
  const [monthlyPriceCalculated, setMonthlyPriceCalculated] = usePersistState<
    string | number | null
  >('', 'monthlyPriceCalculated', STATE_EXPIRY_TIME);
  const [addons, setAddons] = usePersistState<BasketItemT[]>([], 'addons', STATE_EXPIRY_TIME);
  const [bundle, setBundle] = usePersistState<boolean>(false, 'bundle', STATE_EXPIRY_TIME);
  const [promoCodes, setPromoCode] = usePersistState<any[]>([], 'promoCode', STATE_EXPIRY_TIME);
  const { postcodeItem } = usePostcodeContext();
  const { promotions } = usePromotionsContext();

  const isSelectedItem = useCallback(
    (id: string, isAddon: boolean, hasHomePhone: boolean, homePhoneProduct: any) => {
      if (isAddon) {
        return addons.some((addonId) => addonId === id);
      }
      const isHomePhoneSelected = addons.some(
        (addonId) => addonId === homePhoneProduct?.id_product,
      );
      if (isHomePhoneSelected && !hasHomePhone) {
        return false;
      }
      if (!isHomePhoneSelected && hasHomePhone) {
        return false;
      }
      return basketItem === id;
    },
    [basketItem, addons],
  );
  const getBasketItemProduct = useCallback(
    () => products?.find((product) => basketItem && product?.id_product === basketItem) || null,
    [basketItem, products],
  );

  const getAddonsProducts = useCallback(
    () =>
      addons.map((addon) => {
        return products?.find((product) => addons && product?.id_product === addon) || null;
      }) || [],
    [addons, products],
  );

  const isHomePhoneAddonSelected = useCallback(() => {
    const addonsProducts = getAddonsProducts();
    return !!addonsProducts.find((p) => p?.name.includes('Home Phone'));
  }, [getAddonsProducts]);

  const clearBasket = useCallback(() => {
    _setBasketItem(null);
    setPromoCode([]);
    setAddons([]);
    setIsBasketPopupOpen(false);
    setBundle(false);
  }, [_setBasketItem, setPromoCode, setAddons, setIsBasketPopupOpen, setBundle]);

  const removeAddon = useCallback(
    (addonId) => {
      setAddons(addons.filter((addon) => addon !== addonId));
    },
    [setAddons, addons],
  );

  const gaEvent = useGAEvent();

  const removePromoCode = useCallback(
    (promoToRemove: any) => {
      const currentPromoCodes = promoCodes.filter((p) => p?.code !== promoToRemove?.code);
      setPromoCode(currentPromoCodes);

      gaEvent.send({
        action: 'remove_promotion',
        parameters: {
          selected_promotions: currentPromoCodes,
        },
      });
    },
    [promoCodes, setPromoCode, gaEvent],
  );

  const setBasketItem = useCallback(
    (id: string, originalPrice, monthlyPriceCalculated, homePhoneProduct = null) => {
      _setBasketItem(id);
      setMonthlyPriceCalculated(monthlyPriceCalculated);
      const product = products?.find((product) => product?.id_product === id);

      if (!id) {
        setPromoCode([]);
        return false;
      }

      // Apply the promotion automatically
      const promotion = getProductPromotion({
        id_product: id,
        coverage: postcodeItem?.coverage,
        promotions,
      });

      if (!promotion) {
        setPromoCode([]);
        gaEvent.send({
          action: 'add_to_cart',
          parameters: {
            selected_product: {
              ...product,
              originalPrice,
              monthlyPriceCalculated,
            },
            extras: homePhoneProduct ? [homePhoneProduct] : [],
          },
        });
        return false;
      }

      if (Array.isArray(promotion)) {
        setPromoCode(promotion);
      } else {
        setPromoCode([promotion]);
      }

      gaEvent.send({
        action: 'add_to_cart',
        parameters: {
          selected_product: {
            ...product,
            originalPrice,
            monthlyPriceCalculated,
          },
          extras: homePhoneProduct ? [homePhoneProduct] : [],
          selected_promotions: promotion,
        },
      });
    },
    [_setBasketItem, gaEvent, postcodeItem?.coverage, products, promotions, setPromoCode],
  );

  const setBundleProducts = useCallback(
    (bundleProducts) => {
      setAddons((prev) => {
        const addonIds = bundleProducts
          .filter((product) => product.product_type === 'ADDON')
          .map((product) => product.id_product);
        return Array.from(new Set([...prev, ...addonIds]));
      });
      bundleProducts.forEach((product) => {
        if (product.product_type === 'PRODUCT') {
          const promotion = getProductPromotion({
            id_product: product.id_product,
            coverage: postcodeItem?.coverage,
            promotions,
          });

          const originalPrice = getOriginalPrice({
            display_original_price: product.display_original_price,
            exclude_vat_price: product.exclude_vat_price,
            addressType: postcodeItem?.address_type,
            monthly_price: product.monthly_price,
            promo: promotion,
          });

          const monthlyPriceCalculated = getMonthlyPriceCalculated({
            monthly_price: product.monthly_price,
            exclude_vat_price: product.exclude_vat_price,
            addressType: postcodeItem?.address_type,
            promo: promotion,
          });

          setBasketItem(product.id_product, originalPrice, monthlyPriceCalculated);
        }
      });
      setBundle(true);
    },
    [
      setAddons,
      setBasketItem,
      setBundle,
      postcodeItem?.address_type,
      postcodeItem?.coverage,
      promotions,
    ],
  );

  const data = {
    basketItem,
    setBasketItem,
    monthlyPriceCalculated,
    addons,
    setAddons,
    bundle,
    setBundle,
    setBundleProducts,
    getBasketItemProduct,
    getAddonsProducts,
    isHomePhoneAddonSelected,
    promoCodes,
    setPromoCode,
    clearBasket,
    removePromoCode,
    removeAddon,
    isSelectedItem,
    isBasketPopupOpen,
    setIsBasketPopupOpen,
  };
  return <BasketContext.Provider value={data}>{children}</BasketContext.Provider>;
};
