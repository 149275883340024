export function getDuration({ isOvertime }: { isOvertime: boolean }) {
  const envDuration = isOvertime
    ? process.env.NEXT_PUBLIC_CHECKOUT_COUNTDOWN_OVERTIME_MINUTES
    : process.env.NEXT_PUBLIC_CHECKOUT_COUNTDOWN_DURATION_MINUTES;

  const defaultDuration = isOvertime ? 5 : 10;

  return envDuration ? parseInt(envDuration) : defaultDuration;
}

export function getRunsOutAt({ isOvertime }: { isOvertime: boolean }) {
  const value = new Date();
  const duration = getDuration({ isOvertime });
  value.setMinutes(value.getMinutes() + duration);
  return value;
}

export function getDiff(runsOutAt: Date) {
  // return the difference in minutes and seconds between runsOutAt and now
  const diff = runsOutAt.getTime() - new Date().getTime();
  const minutes = Math.floor(diff / (1000 * 60));
  const seconds = Math.floor((diff % (1000 * 60)) / 1000);

  return { minutes, seconds };
}

export function isRunning(runsOutAt: Date | null) {
  return runsOutAt && runsOutAt.getTime() > new Date().getTime();
}
