import { breakpoints } from '@theme/breakpoints';
import styled from 'styled-components';

const { mobilelgMax } = breakpoints;

export default styled.div`
  button:first-child {
    margin-bottom: 1rem;
  }

  .selectButton {
    & > div {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @media ${mobilelgMax} {
        font-size: 1rem;
      }
    }
  }
`;
