import React, { FC } from 'react';
import styled from 'styled-components';
import { Format, ImageDataT } from '@lib/types';
import { breakpoints } from '@theme/breakpoints';
import { renderImage } from '@lib/utils';
import PeekingBenny from '@public/Benny_peeking_over.png';
import Image from 'next/image';
import Link from 'next/link';

interface BennyPeepingI {
  display: boolean;
  link: string;
  image: ImageDataT;
  position: string;
}

const STYLED = {
  wrapper: styled.section`
    position: relative;
    z-index: 1;
  `,
  container: styled.div`
    flex-direction: row;
    justify-content: end;

    @media ${breakpoints.tablet} {
      justify-content: center;
    }

    @media ${breakpoints.desktop} {
      max-width: 800px;
      &.position--left {
        justify-content: flex-start;
      }
      &.position--right {
        justify-content: flex-end;
      }
      &.position--center {
        justify-content: center;
      }
    }

    @media ${breakpoints.desktopLarge} {
      max-width: 1000px;
    }
  `,
  images: styled.div``,
  bubble: styled.div`
    width: 13rem;
    position: absolute;
    top: -75px;
    left: -115px;
    cursor: pointer;
    z-index: 1;
  `,
  benny: styled.div`
    width: 15rem;
    height: 10rem;
    position: relative;
    bottom: -3.3rem;
  `,
  image: styled.div`
    @media ${breakpoints.desktop} {
    }
  `,
};

export const BennyPeeping: FC<BennyPeepingI> = ({ display, link, image, position }) => {
  if (!display) return null;

  return (
    <>
      <STYLED.wrapper>
        <STYLED.container className={`slice__inner position--${position}`}>
          <STYLED.benny>
            {link && image && (
              <Link href={link} passHref>
                <STYLED.bubble>
                  {renderImage({
                    className: 'bubble',
                    alternativeText: image.alternativeText,
                    formats: image.formats,
                    format: Format.LARGE,
                  })}
                </STYLED.bubble>
              </Link>
            )}
            <Image
              src={PeekingBenny}
              alt=""
              className="peeking-benny__image"
              layout="fill"
              objectFit="contain"
            />
          </STYLED.benny>
        </STYLED.container>
      </STYLED.wrapper>
    </>
  );
};
