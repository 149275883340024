import styled from 'styled-components';

export default styled.p`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 26px;
  text-align: left;
  color: rgba(244, 17, 27, 1);
  margin-bottom: 25px;

`;
