import styled from 'styled-components';

export default styled.div`
  margin: 0 0 1rem;
  line-height: 1.3;
  color: var(--colors-white);
  font-size: 1.8rem;
  font-weight: 900;
  font-family: var(--fonts-family-secondary);
  text-align: center;
  > p {
    font-size: 0.75rem;
    font-weight: bold;
    color: var(--colors-white);
  }
`;
