import styled from 'styled-components';

import CheckIcon from '@public/icons/Check-3.svg';

export default styled.ul`
  margin-bottom: 1.5rem;
  li {
    font-weight: 400;
    list-style: none;
    padding-left: 2.5rem;
    background: url('${CheckIcon.src}') top left no-repeat;
    margin-bottom: 0.95rem;
    & > b,
    & > strong {
      color: var(--color-primary);
      font-weight: 700;
    }
  }
`;
