import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';

export default styled.div`
  display: flex;
  justify-content: center;

  button {
    background: var(--colors-primary);
    color: var(--colors-white);
    margin: 0 1rem;
    text-decoration: none;

    @media ${breakpoints.tablet} {
      padding: 0 2.5rem;
    }
  }
`;
