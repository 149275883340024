import React, { FC, useCallback, useEffect, useState } from 'react';
import Link from 'next/link';

import { AccordionItem, Button, Chip } from '@components/Atoms';

import { fromMarkdown } from '@lib/utils';

import { ChipType } from '@components/Atoms/Chip/types';
import { ButtonType } from '@components/Atoms/Button/types';
import { Props, AccordionItemData, CategoryChip } from './types';
import { CategoryType } from '@components/Molecules/Accordion/types';

import { StyledAccordion, StyledAccordionBackground } from './styled';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { rem } from 'polished';

import BennyThumbsUpMobile from '@public/benny_thumb_up_mobile.png';
import { Postcode } from '@components/Organisms/Postcode/Postcode';
import { isExistingCustomerCheck, postcodeSubmitCallback } from '@lib/utils/uprn';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { useRouter } from 'next/router';

const STYLED = {
  wrapper: styled.section<{ sTheme: string; showBackground: boolean }>`
    overflow: hidden;
    position: relative;

    ${({ showBackground }) =>
      showBackground
        ? `
          background-image: url('${BennyThumbsUpMobile.src}');
          background-position: right top;
          background-repeat: no-repeat;

          h2 {
            margin-right: 4.5rem;
          }

          @media ${breakpoints.desktop} {
            background-image: none;

            h2 {
              margin-right: 0;
            }
          }
        `
        : ''};

    ${({ sTheme }) =>
      sTheme === 'bgFadedBlue'
        ? `
        background-color: var(--color-lightest-blue);

      h2 {
        color: black;
        font-family: var(--font-geomanist);
        font-weight: 600;
        margin-bottom: 2rem;
        font-size: ${rem(30)};

        @media ${breakpoints.smallDesktop} {
          font-size: ${rem(40)};
        }
      }
    `
        : ''};

    h2.heading--blue {
      color: var(--color-primary);
    }
  `,
  postcode: styled.div`
    margin-top: 4rem;

    @media ${breakpoints.desktop} {
      margin-top: 6rem;
    }

    h3 {
      font-weight: 600;

      &.heading--blue {
        color: var(--color-primary);
      }

      font-size: ${rem(30)};

      @media ${breakpoints.smallDesktop} {
        font-size: ${rem(40)};
      }
    }

    p {
      margin-top: 1rem;
      margin-bottom: 0;
      font-family: var(--font-geomanist);
      font-weight: 300;
    }
  `,
};

export const Accordion: FC<Props> = ({ data }) => {
  const {
    CTA,
    heading,
    headingBlue,
    FAQ,
    showFilters,
    showBackground = false,
    isSimple,
    alignment = 'left',
    theme = 'default',
    displayPostcodeChecker,
  } = data || {};

  function initialFilteredList(showFilters) {
    if (showFilters) {
      return FAQ.slice(0, 4).sort((a, b) => a.question?.localeCompare(b.question));
    } else {
      return FAQ;
    }
  }

  const { setHasConfirmedAddress, setPostcodeItem, clearPostcode } = usePostcodeContext();
  const router = useRouter();
  const [filteredList, setFilteredList] = useState(initialFilteredList(showFilters));
  const [currentFilter, setCurrentFilter] = useState<string>('');
  const [active, setActive] = useState<string>('');
  const [seeAll, setSeeAll] = useState<boolean>(true);
  const [openKey, setOpenKey] = useState<string | null>();

  useEffect(() => {
    setFilteredList(FAQ);
  }, [FAQ]);

  const handleToggle = (key: string | null) => {
    setOpenKey(openKey !== key ? key : null);
  };

  const handlePostcodeSubmit = useCallback(
    (data) => {
      if (isExistingCustomerCheck(data.coverage, data.oss_type)) {
        return router.push('/error/existing-customer');
      }
      postcodeSubmitCallback({
        data,
        ineligibleCallback: () => {
          return router.push('/service-unavailable');
        },
        successCallback: () => {
          setPostcodeItem(data);
          setHasConfirmedAddress(true);
        },
      });
    },
    [router, setHasConfirmedAddress, setPostcodeItem, clearPostcode],
  );

  const resetAll = () => {
    setCurrentFilter('');
    setActive('');
    setFilteredList(FAQ);
    setSeeAll(true);
    setOpenKey(null);
  };

  const filterItem = (selected: CategoryType) => {
    const filteredList: AccordionItemData[] = [];

    if (currentFilter !== selected) {
      setCurrentFilter(selected);
      FAQ.forEach((item) => {
        if (item.category.includes(selected)) {
          filteredList.push(item);
        }
      });
      if (seeAll) setSeeAll(false);
      filteredList.sort((a, b) => a.question?.localeCompare(b.question));
      setFilteredList(filteredList);
    }
  };

  const categories = [
    {
      label: 'Installation',
      category: CategoryType.INSTALLATION,
    },
    {
      label: 'Speed',
      category: CategoryType.SPEED,
    },
    {
      label: 'Equipment',
      category: CategoryType.EQUIPMENT,
    },
    {
      label: 'Phone',
      category: CategoryType.PHONE,
    },
  ];

  return (
    <STYLED.wrapper className="accordion slice" sTheme={theme} showBackground={!!showBackground}>
      <StyledAccordionBackground className="slice__inner" showBackground={!!showBackground} />
      <div className="slice__inner">
        <StyledAccordion
          className={isSimple ? 'lg:w-1/2 md:w-1/1 sm:w-1/1 mx-auto' : 'lg:w-1/2 md:w-1/1 sm:w-1/1'}
          alignment={alignment}
        >
          {heading && (
            <h2
              className={`text-${alignment} ${
                isSimple ? 'text-black font-black text-xl mb-8' : 'slice__title'
              } ${headingBlue ? 'heading--blue' : ''}`}
            >
              {fromMarkdown({ text: data.heading })}
            </h2>
          )}
          {showFilters && (
            <div className="accordion__filters">
              {categories &&
                categories.map((category: CategoryChip) => {
                  return (
                    <Chip
                      chipType={ChipType.PRIMARY}
                      as="button"
                      className={`accordion__filter ${
                        active === category.category ? 'active' : ''
                      }`}
                      onClick={() => {
                        filterItem(category.category);
                        setActive(category.category);
                        setOpenKey(null);
                      }}
                      key={category.category}
                    >
                      {category.label}
                    </Chip>
                  );
                })}
              {categories && (
                <button
                  id="all"
                  className={`accordion__unfilter ${seeAll ? 'active' : ''}`}
                  onClick={() => resetAll()}
                >
                  See All
                </button>
              )}
            </div>
          )}
          {filteredList && (
            <div className="mb-10 accordion__items">
              {filteredList.map((item: AccordionItemData, index) => {
                return (
                  item.display && (
                    <AccordionItem
                      key={index}
                      toggle={handleToggle}
                      open={openKey === item.question}
                      heading={item.question}
                      body={item.answer}
                      {...item}
                    />
                  )
                );
              })}
            </div>
          )}
          {CTA && (
            <div className="flex justify-center w-full">
              <Link href={CTA.link} passHref>
                <Button buttonType={ButtonType.PRIMARY}>
                  <a href={CTA.link}>{CTA.label}</a>
                </Button>
              </Link>
            </div>
          )}
          {displayPostcodeChecker && (
            <STYLED.postcode>
              <h3 className={`${headingBlue ? 'heading--blue' : ''}`}>
                Can you get Hey!Broadband?
              </h3>
              <p>Enter your postcode to check availability.</p>
              <Postcode showTitle={false} inline={true} onSubmit={handlePostcodeSubmit} />
            </STYLED.postcode>
          )}
        </StyledAccordion>
      </div>
    </STYLED.wrapper>
  );
};
