import styled from 'styled-components';

export default styled.div`
  padding: 0.5rem 0;
  align-self: flex-start;
  p,
  label {
    font-size: 0.6875rem;
    line-height: 1.7;
    color: var(--colors-grey-50);
  }

  p {
    margin-top: 1rem;
  }

  h4 {
    font-size: var(--fonts-sizing-primary);
    font-family: var(--fonts-family-primary);
    font-weight: bold;
    margin-bottom: 1rem;
  }

  .checkoutCheckboxLabel {
    font-family: Roboto;
    font-size: 13px;
    font-weight: 400;
    a {
      font-family: Roboto;
      text-decoration: underline solid;
    }
  }
`;
