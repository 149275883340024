import MiniBasketPaymentSchedule from '@components/Organisms/MiniBasket/MiniBasketPaymentSchedule';
import StyledMiniBasketPromosContainer from '@components/Organisms/MiniBasket/styled/StyledMiniBasketPromosContainer';
import { Props } from '@components/Organisms/MiniBasket/types';
import useBasketContents from '@hooks/useBasketContents';
import { MonthlyPaymentT } from '@lib/types';
import { useRouter } from 'next/router';
import React, { FC, useEffect, useMemo, useState } from 'react';

import { getFormattedPrice, getFriendlyProductTitle } from '@lib/utils';

import StyledMiniBasketContents from './styled/StyledMiniBasketContents';
import StyledMiniBasketContentsRow from './styled/StyledMiniBasketContentsRow';

import { Button } from '@components/Atoms';
import { ButtonType } from '@components/Atoms/Button/types';
import useBasketContext from '@hooks/useBasketContext';
import usePostcodeContext from '@hooks/usePostcodeContext';
import { PromoCode } from '../PromoCode/PromoCode';
import useGAEvent from '@hooks/useGAEvent';
import styled from 'styled-components';
import { breakpoints } from '@theme/breakpoints';
import { validPromoCode } from '@lib/utils/getProductPromotion/getProductPromotion';

import { productController } from '@controllers/index';

const PromoTitle = styled.strong`
  font-size: 1.25rem;
  color: var(--colors-primary-bright);
  font-family: Geomanist, Segoe, 'Segoe UI', 'DejaVu Sans', 'Trebuchet MS', Verdana, 'sans-serif';
  display: block;
  margin-bottom: 0.75rem;

  @media ${breakpoints.desktop} {
    font-size: 1.5rem;
  }
`;

export const MiniBasketContents: FC<Props> = ({
  basketItem,
  showAddress = true,
  readOnly = false,
  fullWidth = false,
}) => {
  const {
    addonsProducts = [],
    promoCodes = [],
    originalBasketItemFormattedPrice,
    addonsMonthlyFormattedPrices,
    addonsOriginalFormattedPrices,
  } = useBasketContents({ basketItem });

  const [monthlyPayment, setMonthlyPayment] = useState<MonthlyPaymentT | null>(null);
  const { addons, removePromoCode, removeAddon, bundle, clearBasket } = useBasketContext();
  const { postcodeItem } = usePostcodeContext();
  const router = useRouter();

  const { id_product, name, display_name, category, speed, first_charge, months } = basketItem;

  const gaEvent = useGAEvent();

  //@promo-debug
  // again, checks if promo is valid based on uprn status / coverage
  const validPromoCodes = useMemo(() => {
    return promoCodes.map((promo) => {
      const valid = validPromoCode({ promotion: promo, coverage: postcodeItem?.coverage });

      return {
        ...promo,
        valid,
      };
    });
  }, [postcodeItem?.coverage, promoCodes]);

  useEffect(() => {
    productController
      .calculateMonthlyPaymentServer({
        products: [id_product, ...addons],
        promotions: validPromoCodes.map((promo) => promo?.id_promotion || ''),
      })
      .then((data) => {
        setMonthlyPayment(data);
      });
  }, [addons, validPromoCodes, id_product]);

  const freeMonthsProductMapping = useMemo(() => {
    const map: Record<string, number> = {};
    if (!monthlyPayment) {
      return map;
    }

    monthlyPayment.details.forEach((product) => {
      map[product.id_product] = product.free_months;
    });

    return map;
  }, [monthlyPayment]);

  const basketItemMonthlyFormattedPrice = useMemo(() => {
    if (!monthlyPayment) return undefined;
    const price = monthlyPayment.details.find(
      (product) => product.id_product === id_product,
    )?.monthly_price;
    if (price) return getFormattedPrice(price);
  }, [id_product, monthlyPayment]);

  return (
    <>
      <StyledMiniBasketContents>
        {showAddress && (
          <StyledMiniBasketContentsRow $firstChild>
            <div>
              <strong>Address</strong>
              <br />
              <div className="row__detail">{postcodeItem?.address}</div>
            </div>
          </StyledMiniBasketContentsRow>
        )}
        <StyledMiniBasketContentsRow $firstChild>
          <div>
            <div className="mb-3">
              <strong>Plan</strong>
            </div>
            <div className="row__info">
              <span className="row__heading">
                {getFriendlyProductTitle({ name, display_name, category })} {bundle ? null : speed}
              </span>
              {!readOnly && (
                <Button
                  buttonType={ButtonType.HYPERLINK}
                  className="remove-button row__info"
                  onClick={async () => {
                    clearBasket();
                    return await router.push('/service-available');
                  }}
                >
                  Reset selection
                </Button>
              )}
            </div>
            <span className="row__subheading">Full Fibre Broadband</span>
          </div>
        </StyledMiniBasketContentsRow>

        <StyledMiniBasketContentsRow>
          <div className="row__detail">
            <span>Set up and Installation fees</span>
            <span className="cost">{`£${getFormattedPrice(parseFloat(first_charge || '0'))}`}</span>
          </div>
        </StyledMiniBasketContentsRow>

        {freeMonthsProductMapping[id_product] ? (
          <StyledMiniBasketContentsRow>
            <div className="row__detail">
              <span>
                First {freeMonthsProductMapping[id_product]}{' '}
                {freeMonthsProductMapping[id_product] > 1 ? 'months' : 'month'} FREE
              </span>
              <span className="cost">{`£${getFormattedPrice(0)}`}</span>
            </div>
          </StyledMiniBasketContentsRow>
        ) : null}

        <StyledMiniBasketContentsRow>
          <div className="row__detail">
            <div>
              <span>Monthly broadband cost</span>
              {freeMonthsProductMapping[id_product] ? (
                <div>
                  <span className="secondary-text">
                    (first payment from month {freeMonthsProductMapping[id_product] + 1})
                  </span>
                </div>
              ) : null}
            </div>
            <span className="cost">
              {originalBasketItemFormattedPrice &&
                originalBasketItemFormattedPrice !== basketItemMonthlyFormattedPrice && (
                  <span className="original-price">{`£${getFormattedPrice(
                    originalBasketItemFormattedPrice,
                  )}`}</span>
                )}
              {`£${basketItemMonthlyFormattedPrice}`}
            </span>
          </div>
        </StyledMiniBasketContentsRow>

        {addonsProducts.length > 0 && !bundle ? (
          <StyledMiniBasketContentsRow>
            <div className="mt-8">
              <div className="mb-3">
                <strong>Extras added</strong>
              </div>
              {addonsProducts?.map((addon: any, index) => (
                <>
                  <div key={addon?.id_product} className="row__info">
                    <span className="row__heading">
                      {!addon?.name.includes('Home Phone')
                        ? getFriendlyProductTitle({ ...addon })
                        : addon?.name?.split(' - ')[0]}
                    </span>
                    {bundle || readOnly ? null : (
                      <Button
                        buttonType={ButtonType.HYPERLINK}
                        className="remove-button row__info"
                        onClick={() => {
                          removeAddon(addon.id_product);

                          gaEvent.send({
                            action: 'remove_item',
                            parameters: {
                              selected_extras: gaEvent.parameters.extras.filter(
                                (extra) => extra?.id_product !== addon.id_product,
                              ),
                            },
                          });
                        }}
                      >
                        Remove
                      </Button>
                    )}
                  </div>

                  <StyledMiniBasketContentsRow>
                    <div className="row__detail">
                      <span>One-off set up fee</span>
                      <span className="cost">{`£${getFormattedPrice(
                        parseFloat(addon.first_charge || '0'),
                      )}`}</span>
                    </div>
                  </StyledMiniBasketContentsRow>

                  {freeMonthsProductMapping[addon?.id_product || ''] ? (
                    <StyledMiniBasketContentsRow>
                      <div className="row__detail">
                        <span>
                          First {freeMonthsProductMapping[addon?.id_product || '']}{' '}
                          {freeMonthsProductMapping[addon?.id_product || ''] > 1
                            ? 'months'
                            : 'month'}{' '}
                          FREE
                        </span>
                        <span className="cost">{`£${getFormattedPrice(0)}`}</span>
                      </div>
                    </StyledMiniBasketContentsRow>
                  ) : null}

                  <StyledMiniBasketContentsRow>
                    <div className="row__detail">
                      <div>
                        <span>Monthly cost</span>
                        {freeMonthsProductMapping[addon?.id_product || ''] ? (
                          <div>
                            <span className="secondary-text">
                              (first payment from month{' '}
                              {freeMonthsProductMapping[addon?.id_product || ''] + 1})
                            </span>
                          </div>
                        ) : null}
                      </div>
                      <span className="cost">
                        {addonsOriginalFormattedPrices[index] && (
                          <span className="original-price">{`£${addonsOriginalFormattedPrices[index]}`}</span>
                        )}
                        &nbsp;
                        {`£${addonsMonthlyFormattedPrices[index]}`}
                      </span>
                    </div>
                  </StyledMiniBasketContentsRow>
                </>
              ))}
            </div>
          </StyledMiniBasketContentsRow>
        ) : null}
      </StyledMiniBasketContents>

      {monthlyPayment ? (
        <MiniBasketPaymentSchedule
          monthlyPayment={monthlyPayment}
          fullWidth={fullWidth}
          monthsProduct={months}
        />
      ) : null}

      {validPromoCodes && !!validPromoCodes.length && (
        <StyledMiniBasketPromosContainer>
          <PromoTitle>Promotions applied</PromoTitle>
          {validPromoCodes.map((promoCode) => {
            return promoCode.valid ? (
              <StyledMiniBasketContentsRow key={promoCode.id_promotion} $promoCode>
                <div>
                  <div>
                    <span className="font-bold text-black">{promoCode.code}</span>
                  </div>
                  <div className="remove-container">
                    {promoCode.text_to_show}
                    {!promoCode.auto && (
                      <Button
                        buttonType={ButtonType.ICON}
                        className="remove-button"
                        onClick={() => removePromoCode(promoCode)}
                      >
                        Remove
                      </Button>
                    )}
                  </div>
                </div>
              </StyledMiniBasketContentsRow>
            ) : (
              postcodeItem && (
                <div className="mb-2 text-[var(--color-danger)] text-sm">
                  <strong>{promoCode.code}</strong> has not been applied to your basket as your
                  address is not eligible for this promotion.
                </div>
              )
            );
          })}
        </StyledMiniBasketPromosContainer>
      )}
      {!readOnly && <PromoCode />}
    </>
  );
};
