import React, { FC, useRef, useState } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import { Icon } from '@components/Atoms';
import { Event, LinkType } from '@lib/types';
import { fromMarkdown } from '@lib/utils';

import useFooterContext from '@hooks/useFooterContext';

import { StyledFooter, FooterWrapper, FooterCopyright } from './styled';
import { Logo } from '@components/Atoms/Logo/Logo';
import useHeaderContext from '@hooks/useHeaderContext';
import { HeaderButton, HeaderDropDown } from '@components/Organisms/Header/styled';
import { Cta } from '@components/Molecules';

import { ButtonType } from '@components/Atoms/Button/types';
import { IconType } from '@providers/FooterProvider/types';

import LinkedinIcon from '@public/icons/linkedin-round.svg';
import InstagramIcon from '@public/icons/instagram-round.svg';
import FacebookIcon from '@public/icons/facebook-round.svg';
import TwitterIcon from '@public/icons/twitter-round.svg';
import FooterImage from '@public/footer-image.png';
import { useRouter } from 'next/router';
import isCheckingOut from '@lib/utils/isCheckingOut';

const socialIconMap = {
  [IconType.LINKEDIN]: LinkedinIcon,
  [IconType.INSTAGRAM]: InstagramIcon,
  [IconType.FACEBOOK]: FacebookIcon,
  [IconType.TWITTER]: TwitterIcon,
};

export const Footer: FC<any> = () => {
  const router = useRouter();
  const { socials, linksColumns, copyright, displayCard, cardTitle, cardBody, cardButton } =
    useFooterContext();
  const { navInfo } = useHeaderContext();
  const dropdown = useRef<HTMLDivElement>(null);

  const [openLinkColumnIndex, setOpenLinkColumnIndex] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(false);
  const restrictedPaths = ['/service-available', '/extras', '/extras', '/checkout'];
  const isRestrictedHeaderFooter = router
    ? restrictedPaths.some((path) => router.asPath?.includes(path)) &&
      !router.asPath?.includes('/success')
    : false;
  const navParents: any =
    navInfo
      ?.filter((x) => x)
      ?.filter((navItem) => !navItem?.moreLink)
      ?.sort((x, y) => {
        if (x?.order < y?.order) return -1;

        if (x?.order > y?.order) return 1;

        return 0;
      }) || [];

  const navChildren: any =
    navInfo
      ?.filter((x) => x)
      ?.filter((navItem) => navItem?.moreLink)
      ?.sort((x, y) => {
        if (x?.moreLinkOrder < y?.moreLinkOrder) return -1;

        if (x?.moreLinkOrder > y?.moreLinkOrder) return 1;

        return 0;
      }) || [];

  return (
    <StyledFooter>
      {isRestrictedHeaderFooter ? (
        <div className="container mx-auto footer__restricted">
          <Logo />
          <p className="rights_reserved">© All rights reserved</p>
        </div>
      ) : (
        <>
          <div className="container mx-auto footer__container">
            <Logo />
            <nav className="footer__navigation">
              {navParents?.length > 0 &&
                navParents?.map(({ id, display, slug, label }) => {
                  if (display) {
                    return (
                      <Link key={id} href={`/${slug}`} passHref>
                        <HeaderButton as="a" key={id} className="footer__navigation__button">
                          {label}
                        </HeaderButton>
                      </Link>
                    );
                  }
                })}
              {/* TODO: remove at end of black friday offer */}
              <Link href="https://offer.heybroadband.co.uk/black-friday/" passHref>
                <HeaderButton as="a" className="footer__navigation__button">
                  <b>Black Friday</b>
                </HeaderButton>
              </Link>
              {navChildren?.length > 0 && (
                <div className="relative footer__more">
                  <HeaderButton
                    className="footer__navigation__button"
                    as="a"
                    onClick={() => {
                      if (dropdown.current) dropdown.current.focus();
                      setOpenDropdown((v) => !v);
                    }}
                  >
                    More...
                  </HeaderButton>
                  <HeaderDropDown
                    ref={dropdown}
                    className={`footer__dropdown ${
                      openDropdown ? 'footer__dropdown--open' : 'footer__dropdown--closed'
                    }`}
                  >
                    <div className="header-dropdown__wrapper">
                      {navChildren?.map(
                        ({ id, display, slug, label }) =>
                          display && (
                            <Link key={id} href={`/${slug}`} passHref>
                              <a href={`/${slug}`}>{label}</a>
                            </Link>
                          ),
                      )}
                    </div>
                  </HeaderDropDown>
                </div>
              )}
            </nav>
            <div className="footer__aside">
              {isCheckingOut(router) ? null : (
                <Cta
                  display
                  link=""
                  label="Sign up"
                  className="sing-up"
                  buttonType={ButtonType.PRIMARY}
                  event={Event.POSTCODE_CHECKER}
                />
              )}
            </div>
          </div>

          <FooterWrapper className="container footer__wrapper">
            <div className="footer__content">
              <div>
                <ul className="footer__social">
                  {socials?.map((socialLink) => (
                    <li key={socialLink.link} className="footer__social__item">
                      <a
                        href={socialLink.link}
                        target="_blank"
                        rel="noreferrer"
                        title={socialLink.label}
                      >
                        <Icon
                          icon={socialIconMap[socialLink.icon]}
                          className="footer__social__item__icon"
                        />
                      </a>
                    </li>
                  ))}
                </ul>

                <div className="footer__voucher-scheme">
                  <Image src={FooterImage} alt="" />
                </div>
              </div>
              <div className="footer__links-container">
                {linksColumns?.map((column: any, columnIndex) => {
                  return (
                    <div className="footer__links" key={column.heading}>
                      <button
                        className={`xx-small footer__links__header${
                          openLinkColumnIndex === columnIndex ? ' footer__links__header--open' : ''
                        }`}
                        onClick={() =>
                          setOpenLinkColumnIndex((v) => (v === columnIndex ? null : columnIndex))
                        }
                      >
                        <span>{column.heading}</span>
                      </button>
                      <ul
                        className={`footer__links__content${
                          openLinkColumnIndex === columnIndex ? ' footer__links__content--open' : ''
                        }`}
                      >
                        {column.listItem?.map(({ link, label, type }) => {
                          const href =
                            type === LinkType.LEGAL && link !== 'legal'
                              ? `/legal/page/${link}`
                              : `/${link}`;

                          return (
                            <li key={`${link}_${label}`}>
                              <Link href={href} passHref>
                                <a href={href}>{label}</a>
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="footer__content">
              {displayCard && (
                <div className="footer__sharing-container">
                  <h3 className="footer__sharing-title">{cardTitle}</h3>
                  {fromMarkdown({ text: cardBody || '', isHeading: false })}
                  {cardButton && <Cta {...cardButton} className="find-button" />}
                </div>
              )}
            </div>
          </FooterWrapper>

          <Link href={'/legal'} passHref>
            <FooterCopyright className="container footer__copyright">{copyright}</FooterCopyright>
          </Link>
        </>
      )}
    </StyledFooter>
  );
};
