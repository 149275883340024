import styled, { css } from 'styled-components';

import { StyledInputProps } from '../types';
import { breakpoints } from '@theme/breakpoints';

function fullWidthStyle({ fullWidth = false }: StyledInputProps) {
  if (fullWidth) {
    return css`
      input:not(.booleanInput) {
        width: 100%;
        &[type='date'] {
          -webkit-min-logical-width: calc(100% - 16px);
        }
      }
    `;
  }
}

function labelOpacityStyle({ isActive = false, isFilled = false }: StyledInputProps) {
  if (!isActive && !isFilled) {
    return css`
      label:not(.checkbox-label):not(.radio-row):not(.hasValue) {
        opacity: 0;
      }
    `;
  } else {
    return css`
      label:not(.checkbox-label):not(.radio-row):not(.hasValue) {
        opacity: 100%;
        text-align: left;
      }
    `;
  }
}

const GlobalInputStyle = css<{ isIOSSafari: boolean }>`
  & {
    padding: 0.7rem 0.875rem;
    background: var(--color-lightest-blue) 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 1px 6px #ada9fb83;
    border-radius: 8px;
    opacity: 1;
    font-size: 19px;
    height: 53px;
    text-align: left;
    width: 100%;
    border: solid 2px transparent;
  }
  ${({ isIOSSafari }) =>
    isIOSSafari &&
    `
    &[type="date"] {
      box-shadow: none;
      background: transparent;
      border: none;
      text-align: left;
    }
    &[type="date"]::-webkit-date-and-time-value {
      text-align: left;
    }
  `}

  ::placeholder {
    color: black;
    font-family: var(--font-geomanist);
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: black;
    font-family: var(--font-geomanist);
  }

  &:active,
  &:focus,
  &:focus-visible,
  &:not(:placeholder-shown) {
    padding-top: 1.85rem;
    outline: none;
  }
`;

export const StyledInput = styled.fieldset<any>`
  &:not(.booleanInput) {
    display: block;
    position: relative;
    text-align: left;
    /* margin-top: 1rem; */
    label:not(.checkbox-label):not(.radio-row) {
      color: #000;
      position: absolute;
      top: 0.5rem;
      left: 0;
      background-color: transparent;
      padding-left: 0.875rem;
      transition: all 0.1s;
      font-family: var(--font-geomanist);
      font-size: 11px;

      &:first-of-type {
        width: 50%;
      }
    }
    ${({ isIOSSafari }) =>
      isIOSSafari &&
      `
        > .date__wrapper {
          background: var(--color-lightest-blue) 0% 0% no-repeat padding-box;
          box-shadow: inset 0px 1px 6px #ada9fb83;
          border-radius: 8px;
          padding: 0;
          height: 53px;
          margin: 0 2px;
        }
      `}

    ${({ isIOSSafari, isAndroid, isActive, isFilled }) =>
      (isIOSSafari || isAndroid) &&
      !isActive &&
      !isFilled &&
      `
        label.date__label {
         font-size: 19px !important;
          top: 0.9rem !important;
        }
      `}

    input {
      ${GlobalInputStyle}

      &.Title {
        width: 50%;
      }
    }

    textarea {
      ${GlobalInputStyle}
      width: 100%;
      height: 330px;
      resize: none;
    }
  }

  ${fullWidthStyle}
  ${labelOpacityStyle}

  input[type="date"] + label:not(.checkbox-label):not(.radio-row) {
    opacity: 1;
  }

  ${({ error }) =>
    error &&
    `
    input[type="text"], input[type="date"] {
      border: solid 2px var(--color-danger);
    }
  `}
  .error {
    text-align: left;
  }

  .input-error-mark {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    background-color: var(--color-danger);
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    color: var(--color-white);
    font-size: 2rem;
    font-weight: 900;
  }

  &.booleanInput {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    margin-bottom: 0.5rem;

    legend {
      flex-basis: 100%;
      margin-bottom: 0.5rem;
      font-weight: 300;
      font-size: 0.9rem;

      @brealpoints.tablet {
        font-size: 1.25rem;
      }
    }

    .radio-row {
      cursor: pointer;
      position: relative;
      box-shadow: none;
    }

    .radio-row:hover,
    .radio-row--selected {
      //background-color: var(--color-selected-off-white);
    }
  }

  .checkbox-group {
    margin: 1.5rem 0;
    &__wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 1rem;
    }

    &__legend {
      font-weight: bold;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .radio-row {
      width: unset;
      input {
        padding: 0;
      }
    }

    input {
      display: none;

      & + span::before {
        display: inline-block;
        content: '';
        width: 1.5rem;
        height: 1.5rem;
        border-radius: 0.25rem;
        margin-right: 0.5rem;
        vertical-align: middle;
        cursor: pointer;
        background-color: var(--color-lightest-blue);
      }
      &:checked + span::before {
        background-image: url('/icons/Checkbox-Gradient.png');
        background-size: cover;
      }
    }
  }

  .react-datepicker__header {
    .react-datepicker__current-month {
      display: block;
    }
  }
`;
