import { breakpoints } from '@theme/breakpoints';
import styled, { css } from 'styled-components';

interface ProgressStepProps {
  $filled?: boolean;
  $active?: boolean;
}

function filledOrActiveCaretStyle({ $filled, $active }: ProgressStepProps) {
  return css`
    color: ${$active || $filled ? 'var(--color-white)' : 'var(--color-primary)'};
    font-weight: ${$active ? 'bold' : 'normal'};
  }
  `;
}

export default styled.div`
  position: relative;
  padding: 0 10px;

  @media ${breakpoints.mobilelg} {
    padding: 0 18px;
    &:not(:last-child)::after {
      content: '>';
      position: absolute;
      right: 0;
      top: 2px;
      ${filledOrActiveCaretStyle}
    }
  }
`;
